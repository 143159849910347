import React from 'react'
import CourseModal from './CourseModal'

const CourseModalWithState = ({
    onClose,
    course,
}) => (
    <CourseModal
        onClose={onClose}
        course={course}
    />
)

export default CourseModalWithState
