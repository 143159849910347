import * as React from 'react'
import {
    Box,
    Flex,
    Text,
    Button,
    Link,
} from 'theme-ui'
import { Trans } from '@lingui/macro'
import CourseInfoHeader from '../../util/CourseInfoHeader'
import CourseImage from '../../util/CourseImage'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const CourseModal = ({
    onClose,
    course,
}) => {
    const clickOutsideRef = React.useRef()
    const buttonsClassName = 'ModalMobileButtons'
    useOnClickOutside(clickOutsideRef, (clickEvent) => {
        if (!clickEvent.target.className.includes(buttonsClassName)) {
            onClose()
        }
    })

    return (
        <Box>
            <Flex
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: window.scrollY,
                    bg: 'rgba(0, 0, 0, 0.5)',
                    px: 7,
                }}
            >
                <Flex
                    ref={clickOutsideRef}
                    sx={{
                        width: ['100%', '50%'],
                        bg: 'white',
                        m: 'auto',
                        my: [100, 'auto'],
                        borderRadius: 1,
                        flexWrap: ['wrap', 'nowrap'],
                        maxHeight: ['100%', 'auto'],
                        overflowY: ['auto', 'hidden'],
                    }}
                >
                    <Box
                        sx={{
                            width: ['100%', '50%'],
                            px: [0, 7],
                            py: [0, 8],
                        }}
                    >
                        <CourseImage
                            image={course.image}
                            sx={{
                                borderRadius: [1, 0],
                                mb: 7,
                            }}
                        />
                        <CourseInfoHeader
                            course={course}
                            sx={{
                                pl: [7, 0],
                            }}
                        />
                    </Box>
                    <Flex
                        sx={{
                            width: ['100%', '50%'],
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            px: 7,
                            py: 8,
                        }}
                    >
                        <Box>
                            <Text
                                variant="secondaryTitle"
                                sx={{
                                    mb: 8,
                                    color: ['greenDark', 'blue'],
                                }}
                            >
                                <Trans>Description</Trans>
                            </Text>
                            <Text dangerouslySetInnerHTML={{ __html: course.description }} variant="paragraph" />
                        </Box>
                        <Flex
                            sx={{
                                justifyContent: 'flex-end',
                                display: ['none', 'flex'],
                                mt: 8,
                            }}
                        >
                            <Button
                                onClick={onClose}
                                variant="outline"
                                sx={{
                                    color: 'greenLight',
                                    border: '2px solid',
                                    mr: 4,
                                }}
                            >
                                <Trans>Go back</Trans>
                            </Button>
                            <Link
                                variant="primaryButton"
                                href={course.url}
                                target="_blank"
                            >
                                <Trans>Go to course</Trans>
                            </Link>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                className={buttonsClassName}
                sx={{
                    display: ['flex', 'none'],
                    position: 'fixed',
                    justifyContent: 'space-between',
                    width: '100%',
                    bottom: 0,
                    p: 6,
                    bg: 'greenDark',
                }}
            >
                <Button
                    className={buttonsClassName}
                    onClick={onClose}
                    variant="outline"
                    sx={{
                        color: 'white',
                        border: '2px solid',
                        mr: 8,
                        width: '50%',
                    }}
                >
                    <Trans>Go back</Trans>
                </Button>
                <Link
                    variant="primaryButton"
                    className={buttonsClassName}
                    href={course.url}
                    target="_blank"
                    sx={{
                        width: '50%',
                    }}
                >
                    <Trans>Go to course</Trans>
                </Link>
            </Flex>
        </Box>
    )
}

export default CourseModal
