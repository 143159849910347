import * as React from 'react'
import {
    Text,
    Flex,
    Box,
} from 'theme-ui'
import { Trans } from '@lingui/macro'
import EmptyState from '../../util/EmptyState'
import HomeCourseCard from './HomeCourseCard'
import HomeWelcomeCard from './HomeWelcomeCard'

const HomeCourses = ({
    courses,
    onOpenCourseCard,
}) => (
    <Flex
        sx={{
            flex: courses.length > 0 ? '' : 1,
            bg: 'greenLighter',
            px: [4, 9],
            pt: 7,
            pb: [200, 7],
        }}
    >
        {courses.length > 0 ? (
            <Flex sx={{ flexWrap: 'wrap' }}>
                <HomeWelcomeCard />
                {courses.map((course) => (
                    <HomeCourseCard
                        key={course.id}
                        course={course}
                        onOpenCourseCard={onOpenCourseCard}
                    />
                ))}
            </Flex>
        ) : (
            <Flex
                sx={{
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        width: [225, 350],
                    }}
                >
                    <Text
                        sx={{ textAlign: 'center' }}
                        variant="welcome"
                    >
                        <Trans>
                            Oops! It looks like we still need to add more courses.{' '}
                            Try to clear some filters.
                        </Trans>
                    </Text>
                    <EmptyState
                        width="100%"
                        height="100%"
                    />
                </Box>
            </Flex>
        )}
    </Flex>
)

export default HomeCourses
