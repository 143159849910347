import * as React from 'react'
import { Image } from 'theme-ui'

const CourseImage = ({
    sx,
    image,
    ...props
}) => (
    <Image
        sx={{
            width: '100%',
            height: [225, 150, 150, 210],
            objectFit: 'cover',
            ...sx,
        }}
        src={image.thumbnails.large.url}
        {...props}
    />
)

export default CourseImage
