import React from 'react'
import HomePageWithState from './pages/home/HomePageWithState'
import PageError from './layout/PageError'
import PageLoading from './layout/PageLoading'
import CookieBanner from './util/CookieBanner'

const App = ({
    hasError,
    isLoading,
}) => {
    if (hasError) {
        return <PageError />
    }

    if (isLoading) {
        return <PageLoading />
    }

    return (
        <>
            <HomePageWithState />
            <CookieBanner />
        </>
    )
}

export default App
