import { HttpLink } from 'apollo-link-http'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

const { REACT_APP_GRAPHQL_URL } = process.env

const createHttpLink = (languageCode) => new HttpLink({
    uri: REACT_APP_GRAPHQL_URL,
    headers: {
        'Accept-Language': languageCode,
    },

})

export default function createApolloClient(languageCode) {
    return new ApolloClient({
        link: createHttpLink(languageCode),
        cache: new InMemoryCache(),
    })
}
