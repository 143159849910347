import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import HomePage from './HomePage'
import useModalState from '../../hooks/useModalState'
import CourseModalWithState from './CourseModalWithState'
import useFetchHomePageData from './useFetchHomePageData'
import useAppState from '../../hooks/useAppState'
import { readQueryString, toQueryString } from '../../../util'
import { selectedFiltersToWhere } from '../../../util/filters'

const HomePageWithState = () => {
    const { search } = useLocation()
    const history = useHistory()
    const selectedFilters = readQueryString(search)
    const courseModalState = useModalState()
    const {
        currentLanguage,
        changeLanguage,
        languages,
        filters,
    } = useAppState()
    selectedFilters.language = currentLanguage
    const where = selectedFiltersToWhere(selectedFilters, languages, filters)
    const {
        error,
        isFetching,
        courses,
    } = useFetchHomePageData(where)

    const handleApplyNewFilter = (newFilter) => {
        const newFilters = {
            ...selectedFilters,
            ...newFilter,
        }
        const newFiltersSearch = toQueryString(newFilters)
        history.push({ pathname: '/', search: newFiltersSearch })
    }

    const handleClearFilters = () => {
        const newFilters = { language: currentLanguage }
        const newFiltersSearch = toQueryString(newFilters)
        history.push({ pathname: '/', search: newFiltersSearch })
    }

    return (
        <>
            <HomePage
                hasError={typeof error !== 'undefined'}
                isFetching={isFetching}
                courses={courses}
                onOpenCourseCard={(course) => {
                    document.body.style.overflow = 'hidden'
                    courseModalState.open({ course })
                }}
                isCourseModalOpen={courseModalState.isOpen}
                selectedFilters={selectedFilters}
                onChangeLanguage={(language) => changeLanguage(language, selectedFilters)}
                onChangeFilter={handleApplyNewFilter}
                onClearFilters={handleClearFilters}
                languages={languages}
                filters={filters}
            />
            {courseModalState.isOpen && (
                <CourseModalWithState
                    onClose={() => {
                        document.body.style.overflow = 'auto'
                        courseModalState.close()
                    }}
                    {...courseModalState.initialState}
                />
            )}
        </>
    )
}

export default HomePageWithState
