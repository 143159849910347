import * as React from 'react'
import { Text } from 'theme-ui'
import { Trans } from '@lingui/macro'

const SectionError = () => (
    <Text
        variant="welcome"
        sx={{
            m: 'auto',
            textAlign: 'center',
        }}
    >
        <Trans>Something went wrong. Please try again later.</Trans>
    </Text>
)

export default SectionError
