import React from 'react'
import { ThemeUIProvider } from 'theme-ui'
import { I18nProvider } from '@lingui/react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import theme from '../styles/theme'

const AppProviders = ({
    apolloClient,
    i18n,
    children,
}) => (
    <BrowserRouter>
        <ThemeUIProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
                <I18nProvider i18n={i18n}>
                    {children}
                </I18nProvider>
            </ApolloProvider>
        </ThemeUIProvider>
    </BrowserRouter>
)

export default AppProviders
