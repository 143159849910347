const text = {
    default: {
        fontFamily: 'body',
        color: 'greenDarker',
    },
    heading: {
        fontFamily: 'body',
        color: 'white',
        fontSize: 5,
    },
    welcome: {
        fontFamily: 'italic',
        fontStyle: 'italic',
        color: 'greenDark',
        fontSize: 4,
        opacity: 0.5,
    },
    title: {
        fontFamily: 'body',
        fontSize: 3,
        fontWeight: 'bold',
        color: 'greenDark',
    },
    subTitle: {
        fontFamily: 'body',
        fontSize: 1,
        color: 'greenDark',
    },
    secondaryTitle: {
        fontFamily: 'body',
        fontSize: 2,
        color: 'blue',
        fontWeight: 'bold',
    },
    paragraph: {
        fontFamily: 'body',
        fontSize: 2,
        color: 'greenDark',
    },
}

export default text
