import * as React from 'react'
import {
    Box,
    Flex,
    Text,
    Button,
} from 'theme-ui'
import { Trans } from '@lingui/macro'

const CourseInfoHeader = ({
    course,
    onGoToPartner,
    ...props
}) => (
    <Flex
        {...props}
        sx={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            ...props.sx,
        }}
    >
        <Flex
            sx={{
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Text variant="subTitle">
                {course.category.name}
            </Text>
            <Text
                variant="title"
                sx={{
                    mt: 1,
                    mb: 5,
                }}
            >
                {course.title}
            </Text>
        </Flex>
        <Box
            sx={{
                display: 'grid',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}
        >
            <Text
                variant="secondaryTitle"
                sx={{
                    fontSize: 1,
                    fontWeight: 'normal',
                    mb: 1,
                }}
            >
                <Trans>Provided by</Trans>
            </Text>
            <Button
                variant="unstyled"
                onClick={() => window.open(course.partner.websiteUrl)}
                sx={{
                    m: 0,
                    p: 0,
                    display: 'inline-flex',
                    fontSize: 1,
                    color: 'blue',
                    fontWeight: 'bold',
                    borderBottom: '1px solid',
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'blue',
                    },
                }}
            >
                {course.partner.companyName}
            </Button>
        </Box>
    </Flex>
)

export default CourseInfoHeader
