import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const HOME_PAGE_QUERY = gql`
    query homePageQuery ($where: CourseWhereInput) {
        courses (where: $where) {
            id
            title
            description
            url
            category {
                id
                name
            }
            partner {
                id
                companyName
                websiteUrl
            }
            image {
                id
                url
                thumbnails {
                    large {
                        url
                    }
                }
            }
        }
    }
`

export default function useFetchHomePageData(where) {
    const variables = { where }
    const {
        error,
        loading,
        data,
    } = useQuery(HOME_PAGE_QUERY, { variables })

    if (typeof error !== 'undefined') {
        return ({ error })
    }

    if (loading) {
        return ({
            isFetching: true,
            courses: [],
        })
    }

    const { courses } = data

    return ({
        isFetching: false,
        courses,
    })
}
