import * as React from 'react'
import { Flex } from 'theme-ui'
import HomeFilters from './HomeFilters'
import HomeCourses from './HomeCourses'
import SectionError from '../../layout/SectionError'
import SectionLoading from '../../layout/SectionLoading'
import PageBanner from '../../layout/PageBanner'

const HomePage = ({
    hasError,
    isFetching,
    courses,
    onOpenCourseCard,
    isCourseModalOpen,
    selectedFilters,
    onChangeLanguage,
    onChangeFilter,
    onClearFilters,
    languages,
    filters,
}) => (
    <Flex
        sx={{
            flexDirection: 'column',
            minHeight: '100vh',
            bg: 'container',
        }}
    >
        <PageBanner />
        <HomeFilters
            isCourseModalOpen={isCourseModalOpen}
            selectedFilters={selectedFilters}
            onChangeLanguage={onChangeLanguage}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
            languages={languages}
            filters={filters}
        />
        {(() => {
            if (hasError) {
                return <SectionError />
            }
            if (isFetching) {
                return <SectionLoading />
            }
            return (
                <HomeCourses
                    courses={courses}
                    onOpenCourseCard={onOpenCourseCard}
                />
            )
        })()}
    </Flex>
)

export default HomePage
