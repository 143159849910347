import { i18n } from '@lingui/core'
import enCatalog from './en/messages'
import esCatalog from './es/messages'
import frCatalog from './fr/messages'
import itCatalog from './it/messages'
import zhCatalog from './zh/messages'

export const LANGUAGE_EN = 'en'
export const LANGUAGE_ES = 'es'
export const LANGUAGE_FR = 'fr'
export const LANGUAGE_IT = 'it'
export const LANGUAGE_ZH = 'zh'

export const DEFAULT_LANGUAGE = LANGUAGE_EN

export const SUPPORTED_LANGUAGES = [
    LANGUAGE_EN,
    LANGUAGE_ES,
    LANGUAGE_FR,
    LANGUAGE_IT,
    LANGUAGE_ZH,
]

const localeConfig = {
    [LANGUAGE_EN]: {
        code: LANGUAGE_EN,
        messages: enCatalog.messages,
        name: 'English',
        plurals: {},
    },
    [LANGUAGE_ES]: {
        code: LANGUAGE_ES,
        messages: esCatalog.messages,
        name: 'Español',
        plurals: {},
    },
    [LANGUAGE_FR]: {
        code: LANGUAGE_FR,
        messages: frCatalog.messages,
        name: 'Français',
        plurals: {},
    },
    [LANGUAGE_IT]: {
        code: LANGUAGE_IT,
        messages: itCatalog.messages,
        name: 'Italiano',
        plurals: {},
    },
    [LANGUAGE_ZH]: {
        code: LANGUAGE_ZH,
        messages: zhCatalog.messages,
        name: '中国人',
        plurals: {},
    },
}

export const catalog = SUPPORTED_LANGUAGES.map((language) => (
    localeConfig[language]
))

export function createLocales(
    locale = DEFAULT_LANGUAGE
) {
    catalog.forEach(({ code, plurals, messages }) => {
        i18n.loadLocaleData(code, { plurals })
        i18n.load(code, messages)
    })
    i18n.activate(locale)
    return i18n
}
