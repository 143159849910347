import * as React from 'react'
import { Flex } from 'theme-ui'
import PageBanner from './PageBanner'
import SectionError from './SectionError'

const PageError = () => (
    <Flex
        sx={{
            flexDirection: 'column',
            minHeight: '100vh',
            bg: 'container',
        }}
    >
        <PageBanner />
        <SectionError />
    </Flex>
)

export default PageError
