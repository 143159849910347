import { defaultButtonStyles } from './buttons'

const linkButtonStyles = {
    ...defaultButtonStyles,
    padding: 5,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'body',
    color: 'white',
    fontSize: 3,
    textDecoration: 'none',
}

export default {
    nav: {
        color: 'white',
        fontWeight: 'semibold',
        textDecoration: 'none',
        fontFamily: 'body',
        fontSize: 3,
    },
    primaryButton: {
        ...linkButtonStyles,
        color: 'white',
        bg: 'greenLight',
        borderRadius: 1,
    },
}
