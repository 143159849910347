import * as React from 'react'
import { Flex } from 'theme-ui'
import PageBanner from './PageBanner'
import SectionLoading from './SectionLoading'

const PageLoading = () => (
    <Flex
        sx={{
            flexDirection: 'column',
            minHeight: '100vh',
            bg: 'container',
        }}
    >
        <PageBanner />
        <SectionLoading />
    </Flex>
)

export default PageLoading
