/* eslint-disable no-param-reassign */
export const where = (...keys) => (...values) => (obj) => keys.every((key) => (key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key])))

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value

export const whereValue = where('value')

export const whereValueIsNot = whereIsNot('value')

export const whereCode = where('code')

export const readQueryString = (string) => {
    const searchParams = new URLSearchParams(string)
    const entries = Array.from(searchParams.entries())
    return entries.reduce((obj, entry) => {
        const key = entry[0]
        const value = entry[1]
        obj[key] = value
        return obj
    }, {})
}

export const toQueryString = (obj = {}) => {
    const searchParams = new URLSearchParams()
    Object.keys(obj).forEach((key) => searchParams.append(key, obj[key]))
    return `?${searchParams.toString()}`
}
