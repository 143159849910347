import * as React from 'react'
import { Box, useThemeUI } from 'theme-ui'
import Loader from 'react-loader-spinner'

const SectionLoading = () => {
    const { theme } = useThemeUI()

    return (
        <Box
            variant="welcome"
            sx={{
                m: 'auto',
            }}
        >
            <Loader
                type="Puff"
                color={theme.colors.banner}
                height={100}
                width={100}
            />
        </Box>
    )
}

export default SectionLoading
