import { whereValue } from '.'

export const getIdFromValue = (value, filters) => filters.find(whereValue(value)).id

export const selectedFiltersToWhere = (
    appliedFilters,
    languages,
    filters
) => Object.keys(appliedFilters).reduce((prev, filterKey) => {
    let whereEntry
    switch (filterKey) {
        case 'language':
            whereEntry = {
                languageId: getIdFromValue(appliedFilters[filterKey], languages),
            }
            break
        case 'category':
            whereEntry = {
                categoryId: getIdFromValue(appliedFilters[filterKey], filters.categories),
            }
            break
        case 'contentType':
            whereEntry = {
                typeOfContentId: getIdFromValue(appliedFilters[filterKey], filters.contentTypes),
            }
            break
        case 'partner':
            whereEntry = {
                partnerId: getIdFromValue(appliedFilters[filterKey], filters.partners),
            }
            break
        case 'ageGroup':
            whereEntry = {
                ageGroupId: getIdFromValue(appliedFilters[filterKey], filters.ageGroups),
            }
            break
        default:
            whereEntry = {}
    }

    return ({
        ...prev,
        ...whereEntry,
    })
}, {})
