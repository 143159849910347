import gql from 'graphql-tag'

export const LANGUAGES_QUERY = gql`
    query languagesQuery {
        languages {
            id
            name
            code
        }
    }
`

export default async function getLanguages(apollo) {
    const query = LANGUAGES_QUERY
    const { data } = await apollo.query({ query })

    const { languages } = data

    return languages.map((language) => ({
        ...language,
        label: language.name,
        value: language.code,
    }))
}
