import buttons from './buttons'
import links from './links'
import text from './text'

export const colorWhite = '#FFFFFF'
export const colorBlack = '#000000'
export const colorPurple = '#A1488F'
export const colorGreenDark = '#243E49'
export const colorGreen = '#176065'
export const colorGreenLight = '#009e97'
export const colorGreenLighter = '#C7DEE7'
export const colorBlueDark = '#11517B'
export const colorBlue = '#63BEE8'

export const colorBanner = colorBlueDark
export const colorFilters = colorGreen
export const colorFilter = colorGreenLight
export const colorContainer = colorGreenLighter

export const space = [
    0, // 0
    5, // 1
    8, // 2
    10, // 3
    14, // 4
    16, // 5
    18, // 6
    20, // 7
    32, // 8
    56, // 9
    64, // 10
    128, // 11
    256, // 12
    512, // 13
]

export const radii = [
    0, // 0
    12, // 1
]

export const fontSizes = [
    8, // 0
    12, // 1
    14, // 2
    16, // 3
    21, // 4
    24, // 5
]

export default {
    fonts: {
        body: 'Helvetica',
        italic: 'Georgia',
    },
    colors: {
        white: colorWhite,
        black: colorBlack,
        purple: colorPurple,
        greenDark: colorGreenDark,
        green: colorGreen,
        greenLight: colorGreenLight,
        greenLighter: colorGreenLighter,
        blueDark: colorBlueDark,
        blue: colorBlue,
        banner: colorBanner,
        filters: colorFilters,
        filter: colorFilter,
        container: colorContainer,
    },
    space,
    radii,
    buttons,
    links,
    text,
    fontSizes,
}
