import * as React from 'react'
import {
    Box,
    useThemeUI,
    Text,
} from 'theme-ui'
import { Trans } from '@lingui/macro'

const HomeWelcomeCard = ({
    ...props
}) => {
    const { theme } = useThemeUI()

    return (
        <Box
            sx={{
                flex: [
                    '0 1 100%',
                    `0 0 calc(33.33% - (2 * ${theme.space[5]}px))`,
                    `0 0 calc(25% - (2 * ${theme.space[5]}px))`,
                    `0 0 calc(20% - (2 * ${theme.space[5]}px))`,
                ],
                m: 5,
            }}
            {...props}
        >
            <Text variant="welcome">
                <Trans>
                    Welcome! Browse here through all the course materials provided by our partners.
                </Trans>
            </Text>
        </Box>
    )
}

export default HomeWelcomeCard
