import * as React from 'react'
import {
    Flex,
    Text,
    Button,
} from 'theme-ui'
import ReactCookieBanner from 'react-cookie-banner'
import { Trans } from '@lingui/macro'
import Icon from './Icon'

const CookieBanner = () => (
    <ReactCookieBanner
        cookie="stem-at-home-user-accepted-cookies2"
        dismissOnScroll={false}
    >
        {(onAccept) => (
            <Flex
                sx={{
                    width: '100%',
                    position: 'fixed',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    bg: 'black',
                    py: 6,
                    px: [4, 9],
                    zIndex: 10,
                }}
            >
                <Text
                    variant="paragraph"
                    color="white"
                >
                    <Trans>This website uses cookies to ensure you get the best experience.</Trans>
                </Text>
                <Button
                    variant="unstyled"
                    onClick={onAccept}
                >
                    <Icon name="cross" />
                </Button>
            </Flex>
        )}
    </ReactCookieBanner>
)

export default CookieBanner
