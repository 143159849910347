import * as React from 'react'
import {
    Button,
    Box,
    Flex,
    Text,
} from 'theme-ui'
import Icon from '../../util/Icon'
import { whereValueIsNot, whereValue } from '../../../util'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const HomeFilter = ({
    value,
    placeholder,
    onChange,
    options,
    showClearButton,
    ...props
}) => {
    const clickOutsideRef = React.useRef()
    const [isOpen, setIsOpen] = React.useState(false)
    const className = `DropdownToggle${typeof value === 'undefined' ? options[0].id : value}`
    useOnClickOutside(clickOutsideRef, (clickEvent) => {
        if (!clickEvent.target.className.includes(className)) {
            setIsOpen(false)
        }
    })
    let dropdownOptions = options
    if (value) {
        dropdownOptions = options.filter(whereValueIsNot(value))
    }

    return (
        <Box
            className={className}
            sx={{
                flex: '0 0 auto',
            }}
            {...props}
        >
            <Button
                className={className}
                onClick={() => setIsOpen(!isOpen)}
                variant="outline"
                sx={{
                    mt: 5,
                    bg: 'green',
                }}
            >
                <Flex
                    className={className}
                    sx={{ alignItems: 'center' }}
                >
                    <Text
                        className={className}
                        sx={{ whiteSpace: 'nowrap', mr: 3 }}
                    >
                        {value
                            ? options.find(whereValue(value)).label
                            : placeholder}
                    </Text>
                    <Icon
                        className={className}
                        name="chevronDown"
                    />
                </Flex>
            </Button>
            {isOpen && (
                <Flex
                    ref={clickOutsideRef}
                    sx={{
                        position: 'absolute',
                        flexDirection: 'column',
                        width: ['92%', 'auto'],
                        mt: [0, 1],
                        bg: 'greenDark',
                        border: '1px solid',
                        borderColor: 'white',
                        borderRadius: 1,
                        bottom: [showClearButton ? 115 : 80, 'auto'],
                        left: [4, 'auto'],
                        zIndex: 5,
                    }}
                >
                    {dropdownOptions.map(({ label, value: optionValue }, index) => (
                        <Button
                            key={optionValue}
                            onClick={() => {
                                onChange(optionValue)
                                setIsOpen(false)
                            }}
                            variant="dropdown"
                            sx={{
                                borderRadius: (index === 0 || index + 1 === dropdownOptions.length)
                                    ? 1 : 0,
                                textAlign: 'left',
                            }}
                        >
                            {label}
                        </Button>
                    ))}
                </Flex>
            )}
        </Box>
    )
}

export default HomeFilter
