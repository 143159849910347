import * as React from 'react'
import { Button, Flex } from 'theme-ui'
import { Trans } from '@lingui/macro'
import Banner from '../util/Banner'
import useAppState from '../hooks/useAppState'
import { MAIN_SITE_URL } from '../../util/constants'
import { whereCode } from '../../util'

const PageBanner = () => {
    const {
        isLoading,
        currentLanguage,
        languages,
    } = useAppState()

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            bg="banner"
            py={4}
            px={[4, 9]}
        >
            <Banner />
            {!isLoading && (
                <Button
                    variant="outline"
                    onClick={() => window.open(`${MAIN_SITE_URL}/${languages.find(whereCode(currentLanguage)).name.toLowerCase()}`)}
                    sx={{
                        maxWidth: [90, '100%'],
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        px: [1, 5],
                        py: [4, 5],
                    }}
                >
                    <Trans>Main site</Trans>
                </Button>
            )}
        </Flex>
    )
}

export default PageBanner
