import * as React from 'react'
import {
    Flex,
    useThemeUI,
} from 'theme-ui'
import CourseInfoHeader from '../../util/CourseInfoHeader'
import CourseImage from '../../util/CourseImage'

const HomeCourseCard = ({
    course,
    onOpenCourseCard,
    ...props
}) => {
    const { theme } = useThemeUI()
    return (
        <Flex
            p={0}
            m={5}
            sx={{
                flexDirection: 'column',
                flex: [
                    '0 1 100%',
                    `0 0 calc(33.33% - (2 * ${theme.space[5]}px))`,
                    `0 0 calc(25% - (2 * ${theme.space[5]}px))`,
                    `0 0 calc(20% - (2 * ${theme.space[5]}px))`,
                ],
                borderRadius: 1,
                bg: 'white',
                cursor: 'pointer',
            }}
            {...props}
            onClick={() => onOpenCourseCard(course)}
        >
            {course.image !== null && (
                <CourseImage
                    image={course.image}
                    sx={{
                        borderRadius: 1,
                        mb: 6,
                    }}
                />
            )}
            <CourseInfoHeader
                course={course}
                sx={{
                    px: 5,
                    pb: 5,
                }}
            />
        </Flex>
    )
}

export default HomeCourseCard
