export const defaultButtonStyles = {
    '&:hover': {
        cursor: 'pointer',
    },
    '&:active': {
        opacity: 0.5,
    },
}

const buttons = {
    unstyled: {
        ...defaultButtonStyles,
        m: 0,
        p: 0,
        borderRadius: 0,
        bg: 'transparent',
    },
    primary: {
        ...defaultButtonStyles,
        color: 'white',
        bg: 'greenLight',
        borderRadius: 1,
    },
    outline: {
        ...defaultButtonStyles,
        color: 'white',
        bg: 'transparent',
        border: '1px solid white',
        borderRadius: 1,
        padding: 5,
    },
    dropdown: {
        ...defaultButtonStyles,
        bg: 'transparent',
        borderRadius: 0,
        py: 5,
        px: 8,
        '&:hover': {
            cursor: 'pointer',
            bg: 'green',
        },
    },
}

export default buttons
