import * as React from 'react'
import {
    Box,
    Text,
    Flex,
    useThemeUI,
    Button,
} from 'theme-ui'
import { Trans } from '@lingui/macro'
import HomeFilter from './HomeFilter'
import Icon from '../../util/Icon'

const HomeFilters = ({
    isCourseModalOpen,
    selectedFilters,
    onChangeLanguage,
    onChangeFilter,
    onClearFilters,
    languages,
    filters,
}) => {
    const { theme } = useThemeUI()
    const showClearButton = Object.keys(selectedFilters).length > 1

    return (
        <Box
            bg="greenDark"
            sx={{
                display: [`${isCourseModalOpen ? 'none' : 'block'}`, 'block'],
                position: ['fixed', 'relative'],
                bottom: 0,
                order: [1, 0],
                bg: 'greenDark',
                maxWidth: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        py: 7,
                        px: [4, 9],
                    }}
                >
                    <Text variant="heading">
                        <Trans>Filter courses by</Trans>
                    </Text>
                    <Flex
                        id="FilterFlex"
                        sx={{
                            overflow: ['auto', 'hidden'],
                        }}
                    >
                        <HomeFilter
                            value={selectedFilters.category}
                            placeholder={<Trans>Category</Trans>}
                            onChange={(category) => onChangeFilter({ category })}
                            options={filters.categories}
                            showClearButton={showClearButton}
                            sx={{ mr: 7 }}
                        />
                        <HomeFilter
                            value={selectedFilters.contentType}
                            placeholder={<Trans>Content type</Trans>}
                            onChange={(contentType) => onChangeFilter({ contentType })}
                            options={filters.contentTypes}
                            showClearButton={showClearButton}
                            sx={{ mr: 7 }}
                        />
                        <HomeFilter
                            value={selectedFilters.partner}
                            placeholder={<Trans>Partner</Trans>}
                            onChange={(partner) => onChangeFilter({ partner })}
                            options={filters.partners}
                            showClearButton={showClearButton}
                            sx={{ mr: 7 }}
                        />
                        <HomeFilter
                            value={selectedFilters.ageGroup}
                            placeholder={<Trans>Age group</Trans>}
                            onChange={(ageGroup) => onChangeFilter({ ageGroup })}
                            options={filters.ageGroups}
                            showClearButton={showClearButton}
                            sx={{ mr: [7, 0] }}
                        />
                        <HomeFilter
                            value={selectedFilters.language}
                            onChange={onChangeLanguage}
                            options={languages}
                            showClearButton={showClearButton}
                            sx={{
                                ml: 'auto',
                                mr: 0,
                            }}
                        />
                    </Flex>
                    {showClearButton && (
                        <Button
                            variant="unstyled"
                            onClick={onClearFilters}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 7,
                                borderBottom: '1px solid',
                                borderColor: 'greenDark',
                                '&:hover': {
                                    borderColor: 'white',
                                },
                            }}
                        >
                            <Icon name="cross" />
                            <Text
                                sx={{
                                    ml: 2,
                                    fontSize: 2,
                                }}
                            >
                                <Trans>Clear all</Trans>
                            </Text>
                        </Button>
                    )}
                </Box>
                <Box
                    sx={{
                        display: ['block', 'none'],
                        background: 'linear-gradient(90deg, rgba(36, 62, 73, 0) 0%, #243E49 100%)',
                        position: 'absolute',
                        width: theme.space[10],
                        height: '100%',
                        top: 0,
                        right: 0,
                    }}
                />
            </Box>
        </Box>
    )
}

export default HomeFilters
