import React from 'react'
import { createBrowserHistory } from 'history'
import getApolloClient from '../apollo/getApolloClient'
import AppStateProvider from '../providers/AppStateProvider'
import App from './App'
import AppProviders from './AppProviders'
import { readQueryString, toQueryString, whereCode } from '../util'
import getFilters from '../apollo/queries/getFilters'
import getLanguages from '../apollo/queries/getLanguages'
import { DEFAULT_LANGUAGE, createLocales, catalog } from '../locales'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.i18n = createLocales(DEFAULT_LANGUAGE)
        this.history = createBrowserHistory()
        this.apolloClient = getApolloClient(DEFAULT_LANGUAGE)

        this.handleChangeLanguage = this.handleChangeLanguage.bind(this)

        /* eslint-disable react/no-unused-state */
        this.state = {
            hasError: false,
            isLoading: true,
            currentLanguage: DEFAULT_LANGUAGE,
            changeLanguage: this.handleChangeLanguage,
            languages: catalog,
            filters: {},
        }
    }

    async componentDidMount() {
        try {
            await this.initLanguage()
            await this.fetchInitialData()
            this.setState({ isLoading: false })
        } catch (error) {
            this.setState({ hasError: true })
        }
    }

    async handleChangeLanguage(locale, currentFilters) {
        this.i18n.activate(locale)
        const newFiltersSearch = toQueryString({ ...currentFilters, language: locale })
        this.history.push({ pathname: '/', search: newFiltersSearch })
        this.setState({ currentLanguage: locale })
    }

    async initLanguage() {
        const { currentLanguage } = this.state
        const selectedFilters = readQueryString(this.history.location.search)
        let { language } = selectedFilters
        const languages = await getLanguages(this.apolloClient)
        if (typeof language === 'undefined' || typeof languages.find(whereCode(language)) === 'undefined') {
            language = currentLanguage
        }
        this.handleChangeLanguage(language, selectedFilters)
        this.setState({ languages })
    }

    async fetchInitialData() {
        const { apolloClient } = this
        const filters = await getFilters(apolloClient)
        this.setState({ filters })
    }

    render() {
        const { apolloClient } = this
        const {
            isLoading,
            hasError,
        } = this.state

        return (
            <AppStateProvider value={this.state}>
                <AppProviders
                    i18n={this.i18n}
                    apolloClient={apolloClient}
                >
                    <App
                        hasError={hasError}
                        isLoading={isLoading}
                    />
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
