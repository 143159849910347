import React from 'react'

const ChevronDown = ({ ...props }) => (
    <svg width="16" height="10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7.25 9.149a1 1 0 001.5 0l6.446-7.315c.57-.646.111-1.662-.75-1.662H1.554c-.861 0-1.32 1.016-.75 1.662L7.25 9.149z" fill="#fff" />
    </svg>
)

const Cross = ({ ...props }) => (
    <svg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1 1l9 9M1 10l9-9" stroke="#fff" strokeWidth="2" />
    </svg>
)

const Icon = ({
    name,
    ...props
}) => {
    switch (name) {
        case 'chevronDown':
            return <ChevronDown {...props} />
        case 'cross':
            return <Cross {...props} />
        default:
            return null
    }
}

export default Icon
