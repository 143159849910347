import React from 'react'
import { createRoot } from 'react-dom/client'
import { setupGtagManager } from './client/util/google'
import AppWithState from './client/components/AppWithState'
import './index.css'

const {
    REACT_APP_ENV: env,
    REACT_APP_GTAG_ID: gtagId,
} = process.env

// Google analytics
if (env === 'production') {
    setupGtagManager(window, document, 'script', 'dataLayer', gtagId)
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<AppWithState />)
