import gql from 'graphql-tag'

export const FILTERS_QUERY = gql`
    query filtersQuery {
        ageGroups {
            id
            name
            notes
        }
        categories {
            id
            name
        }
        partners {
            id
            websiteUrl
            description
            companyName
            logo {
                id
                url
                thumbnails {
                    large {
                        url
                    }
                }
            }
        }
        typeOfContents {
            id
            name
        }
    }
`

export default async function getFilters(apollo) {
    const query = FILTERS_QUERY
    const { data } = await apollo.query({ query })

    const {
        ageGroups,
        categories,
        partners,
        typeOfContents,
    } = data

    return ({
        ageGroups: ageGroups.map(({ name, id }) => ({ label: name, value: name, id })),
        categories: categories.map(({ name, id }) => ({ label: name, value: name, id })),
        partners: partners.map(({ companyName, id }) => ({
            id,
            label: companyName,
            value: companyName,
        })),
        contentTypes: typeOfContents.map(({ name, id }) => ({ label: name, value: name, id })),
    })
}
